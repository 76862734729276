/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const AddMarkupOrTax = /* GraphQL */ `
  query AddMarkupOrTax(
    $default: Int
    $for: String
    $name: String
    $percent: Float
    $status: Int
    $team_id: Int!
    $type: String!
    $user_id: Int!
    $value: Float
  ) {
    AddMarkupOrTax(
      default: $default
      for: $for
      name: $name
      percent: $percent
      status: $status
      team_id: $team_id
      type: $type
      user_id: $user_id
      value: $value
    )
  }
`;
export const addDocOrders = /* GraphQL */ `
  query AddDocOrders(
    $action: String!
    $data: String!
    $doc_id: Int!
    $doc_type_id: Int!
    $order_id: Int
    $scope_ids: String!
    $scope_type_id: Int
    $token: String!
  ) {
    addDocOrders(
      action: $action
      data: $data
      doc_id: $doc_id
      doc_type_id: $doc_type_id
      order_id: $order_id
      scope_ids: $scope_ids
      scope_type_id: $scope_type_id
      token: $token
    )
  }
`;
export const addDocSubLineItem = /* GraphQL */ `
  query AddDocSubLineItem($doc_line_item_id: Int, $sub_line_item: String) {
    addDocSubLineItem(
      doc_line_item_id: $doc_line_item_id
      sub_line_item: $sub_line_item
    )
  }
`;
export const adminLogin = /* GraphQL */ `
  query AdminLogin($password: String, $user_name: String) {
    adminLogin(password: $password, user_name: $user_name)
  }
`;
export const applyWorkOrderTemplate = /* GraphQL */ `
  query ApplyWorkOrderTemplate($doc_id: Int, $doc_type_id: Int, $temp_id: Int) {
    applyWorkOrderTemplate(
      doc_id: $doc_id
      doc_type_id: $doc_type_id
      temp_id: $temp_id
    )
  }
`;
export const assignDefaultTemplates = /* GraphQL */ `
  query AssignDefaultTemplates(
    $doc_type_id: Int
    $scope_type_id: Int
    $template_id: Int
    $user_id: Int
  ) {
    assignDefaultTemplates(
      doc_type_id: $doc_type_id
      scope_type_id: $scope_type_id
      template_id: $template_id
      user_id: $user_id
    )
  }
`;
export const checkClientTempTokenFromRedis = /* GraphQL */ `
  query CheckClientTempTokenFromRedis($client_id: Int, $logged_in_id: Int) {
    checkClientTempTokenFromRedis(
      client_id: $client_id
      logged_in_id: $logged_in_id
    )
  }
`;
export const countContactsByClientID = /* GraphQL */ `
  query CountContactsByClientID($client_id: Int!, $type: String!) {
    countContactsByClientID(client_id: $client_id, type: $type)
  }
`;
export const createAllUserDocsOnElasticsearch = /* GraphQL */ `
  query CreateAllUserDocsOnElasticsearch(
    $doc_type_id: Int
    $email: String
    $end_date: String
    $start_date: String
    $team_id: Int
    $user_id: Int
  ) {
    createAllUserDocsOnElasticsearch(
      doc_type_id: $doc_type_id
      email: $email
      end_date: $end_date
      start_date: $start_date
      team_id: $team_id
      user_id: $user_id
    )
  }
`;
export const createAllUserTempsOnElasticsearch = /* GraphQL */ `
  query CreateAllUserTempsOnElasticsearch(
    $doc_type_id: Int
    $email: String
    $end_date: String
    $start_date: String
    $team_id: Int
    $user_id: Int
  ) {
    createAllUserTempsOnElasticsearch(
      doc_type_id: $doc_type_id
      email: $email
      end_date: $end_date
      start_date: $start_date
      team_id: $team_id
      user_id: $user_id
    )
  }
`;
export const createAuthStartsupply = /* GraphQL */ `
  query CreateAuthStartsupply($data: String, $doc_id: Int) {
    createAuthStartsupply(data: $data, doc_id: $doc_id)
  }
`;
export const createContactAddress = /* GraphQL */ `
  query CreateContactAddress(
    $address: String
    $address_2: String
    $city: String
    $company: String
    $country_id: Int
    $locked: Int
    $name: String
    $postcode: String
    $status: Int
    $zone_id: Int
  ) {
    createContactAddress(
      address: $address
      address_2: $address_2
      city: $city
      company: $company
      country_id: $country_id
      locked: $locked
      name: $name
      postcode: $postcode
      status: $status
      zone_id: $zone_id
    )
  }
`;
export const createContactInfo = /* GraphQL */ `
  query CreateContactInfo(
    $address_id: Int
    $company: String
    $customer_id: Int
    $email: String
    $first_name: String
    $last_name: String
    $phone: String
    $template_id: Int
    $type: String
    $used_default: Int
  ) {
    createContactInfo(
      address_id: $address_id
      company: $company
      customer_id: $customer_id
      email: $email
      first_name: $first_name
      last_name: $last_name
      phone: $phone
      template_id: $template_id
      type: $type
      used_default: $used_default
    )
  }
`;
export const createDocCatWithLineItems = /* GraphQL */ `
  query CreateDocCatWithLineItems(
    $doc_id: Int
    $doc_structure_id: Int
    $lineObject: String
    $strObject: String
  ) {
    createDocCatWithLineItems(
      doc_id: $doc_id
      doc_structure_id: $doc_structure_id
      lineObject: $lineObject
      strObject: $strObject
    )
  }
`;
export const createDocCategory = /* GraphQL */ `
  query CreateDocCategory(
    $category_id: Int
    $doc_id: Int
    $doc_structure_id: Int
    $name: String
    $notes: String
    $sort_order: Int
  ) {
    createDocCategory(
      category_id: $category_id
      doc_id: $doc_id
      doc_structure_id: $doc_structure_id
      name: $name
      notes: $notes
      sort_order: $sort_order
    )
  }
`;
export const createDocLineItem = /* GraphQL */ `
  query CreateDocLineItem(
    $adjusted_quantity: Float
    $algorithm: String
    $apply_op: Int
    $color: String
    $convert_round: Int
    $convert_to: String
    $cost: Float
    $description: String
    $doc_category_id: Int
    $doc_id: Int
    $doc_structure_id: Int
    $labor_cost: Float
    $line_item_code: String
    $line_item_id: Int
    $manufacturer: String
    $manufacturer_id: Int
    $material_cost: Float
    $name: String
    $notes: String
    $overhead: Float
    $product: String
    $profit: Float
    $quantity: Float
    $ratio_from: Float
    $ratio_to: Float
    $remove_prices: Int
    $scope_type_id: Int
    $sku: String
    $sort_order: Int
    $sub_line_item: String
    $template_data: String
    $unit: String
    $waste: Float
  ) {
    createDocLineItem(
      adjusted_quantity: $adjusted_quantity
      algorithm: $algorithm
      apply_op: $apply_op
      color: $color
      convert_round: $convert_round
      convert_to: $convert_to
      cost: $cost
      description: $description
      doc_category_id: $doc_category_id
      doc_id: $doc_id
      doc_structure_id: $doc_structure_id
      labor_cost: $labor_cost
      line_item_code: $line_item_code
      line_item_id: $line_item_id
      manufacturer: $manufacturer
      manufacturer_id: $manufacturer_id
      material_cost: $material_cost
      name: $name
      notes: $notes
      overhead: $overhead
      product: $product
      profit: $profit
      quantity: $quantity
      ratio_from: $ratio_from
      ratio_to: $ratio_to
      remove_prices: $remove_prices
      scope_type_id: $scope_type_id
      sku: $sku
      sort_order: $sort_order
      sub_line_item: $sub_line_item
      template_data: $template_data
      unit: $unit
      waste: $waste
    )
  }
`;
export const createDocWithTemplate = /* GraphQL */ `
  query CreateDocWithTemplate($doc_data: String, $doc_id: Int, $temp_id: Int) {
    createDocWithTemplate(
      doc_data: $doc_data
      doc_id: $doc_id
      temp_id: $temp_id
    )
  }
`;
export const createDocument = /* GraphQL */ `
  query CreateDocument(
    $claim_number: String
    $contact_id: Int
    $date_created: String
    $date_updated: String
    $doc_type_id: Int
    $location: String
    $name: String
    $owner_contact_id: Int
    $ref_id: String
    $team_id: Int
    $template_id: Int
    $user_id: Int
  ) {
    createDocument(
      claim_number: $claim_number
      contact_id: $contact_id
      date_created: $date_created
      date_updated: $date_updated
      doc_type_id: $doc_type_id
      location: $location
      name: $name
      owner_contact_id: $owner_contact_id
      ref_id: $ref_id
      team_id: $team_id
      template_id: $template_id
      user_id: $user_id
    )
  }
`;
export const createDocumentContact = /* GraphQL */ `
  query CreateDocumentContact(
    $customer_id: Int
    $email: String
    $first_name: String
    $last_name: String
    $phone: String
  ) {
    createDocumentContact(
      customer_id: $customer_id
      email: $email
      first_name: $first_name
      last_name: $last_name
      phone: $phone
    )
  }
`;
export const createFinancingOptions = /* GraphQL */ `
  query CreateFinancingOptions(
    $doc_id: Int!
    $financing_option: String
    $is_default: String
    $user_id: Int!
  ) {
    createFinancingOptions(
      doc_id: $doc_id
      financing_option: $financing_option
      is_default: $is_default
      user_id: $user_id
    )
  }
`;
export const createLineItem = /* GraphQL */ `
  query CreateLineItem(
    $adjusted_quantity: Float
    $algorithm: String
    $apply_op: Int
    $convert_round: Int
    $convert_to: String
    $cost: Float
    $description: String
    $doc_type_id: Int
    $labor_cost: Float
    $line_item_code: String
    $manufacturer: String
    $manufacturer_id: Int
    $material_cost: Float
    $name: String
    $notes: String
    $overhead: Float
    $product: String
    $profit: Float
    $quantity: Float
    $ratio_from: Float
    $ratio_to: Float
    $scope_type_id: Int
    $sku: String
    $status: Int
    $sub_line_item: String
    $team_id: Int
    $unit: String
    $user_id: Int
    $waste: Float
  ) {
    createLineItem(
      adjusted_quantity: $adjusted_quantity
      algorithm: $algorithm
      apply_op: $apply_op
      convert_round: $convert_round
      convert_to: $convert_to
      cost: $cost
      description: $description
      doc_type_id: $doc_type_id
      labor_cost: $labor_cost
      line_item_code: $line_item_code
      manufacturer: $manufacturer
      manufacturer_id: $manufacturer_id
      material_cost: $material_cost
      name: $name
      notes: $notes
      overhead: $overhead
      product: $product
      profit: $profit
      quantity: $quantity
      ratio_from: $ratio_from
      ratio_to: $ratio_to
      scope_type_id: $scope_type_id
      sku: $sku
      status: $status
      sub_line_item: $sub_line_item
      team_id: $team_id
      unit: $unit
      user_id: $user_id
      waste: $waste
    )
  }
`;
export const createOnRedis = /* GraphQL */ `
  query CreateOnRedis($data: String, $id: Int, $index: String) {
    createOnRedis(data: $data, id: $id, index: $index)
  }
`;
export const createOwnerContact = /* GraphQL */ `
  query CreateOwnerContact(
    $customer_id: Int
    $email: String
    $first_name: String
    $last_name: String
    $phone: String
  ) {
    createOwnerContact(
      customer_id: $customer_id
      email: $email
      first_name: $first_name
      last_name: $last_name
      phone: $phone
    )
  }
`;
export const createProductAndManufacturerES = /* GraphQL */ `
  query CreateProductAndManufacturerES($data: String) {
    createProductAndManufacturerES(data: $data)
  }
`;
export const createStructure = /* GraphQL */ `
  query CreateStructure(
    $doc_id: Int
    $name: String
    $sort_order: Int
    $structure_id: Int
  ) {
    createStructure(
      doc_id: $doc_id
      name: $name
      sort_order: $sort_order
      structure_id: $structure_id
    )
  }
`;
export const createTemplate = /* GraphQL */ `
  query CreateTemplate(
    $default_category_ids: String
    $default_template_id: Int
    $doc_type_id: Int!
    $email: String!
    $name: String!
    $team_id: Int!
    $user_id: Int!
  ) {
    createTemplate(
      default_category_ids: $default_category_ids
      default_template_id: $default_template_id
      doc_type_id: $doc_type_id
      email: $email
      name: $name
      team_id: $team_id
      user_id: $user_id
    )
  }
`;
export const deleteAddress = /* GraphQL */ `
  query DeleteAddress($address_id: Int) {
    deleteAddress(address_id: $address_id)
  }
`;
export const deleteContact = /* GraphQL */ `
  query DeleteContact($contact_id: Int) {
    deleteContact(contact_id: $contact_id)
  }
`;
export const deleteDocMarkup = /* GraphQL */ `
  query DeleteDocMarkup($doc_markup_id: Int!) {
    deleteDocMarkup(doc_markup_id: $doc_markup_id)
  }
`;
export const deleteDocumentCategory = /* GraphQL */ `
  query DeleteDocumentCategory($doc_category_id: Int) {
    deleteDocumentCategory(doc_category_id: $doc_category_id)
  }
`;
export const deleteDocumentWorkOrder = /* GraphQL */ `
  query DeleteDocumentWorkOrder($doc_id: Int) {
    deleteDocumentWorkOrder(doc_id: $doc_id)
  }
`;
export const deleteFile = /* GraphQL */ `
  query DeleteFile($customerId: Int!, $fileId: Int!, $fileName: String) {
    deleteFile(customerId: $customerId, fileId: $fileId, fileName: $fileName)
  }
`;
export const deleteLineItem = /* GraphQL */ `
  query DeleteLineItem($doc_line_item_id: Int) {
    deleteLineItem(doc_line_item_id: $doc_line_item_id)
  }
`;
export const deleteMarkupOrTax = /* GraphQL */ `
  query DeleteMarkupOrTax($markup_id: Int!) {
    deleteMarkupOrTax(markup_id: $markup_id)
  }
`;
export const deleteStructure = /* GraphQL */ `
  query DeleteStructure($doc_structure_id: Int) {
    deleteStructure(doc_structure_id: $doc_structure_id)
  }
`;
export const downloadTemplateData = /* GraphQL */ `
  query DownloadTemplateData($template_id: Int) {
    downloadTemplateData(template_id: $template_id)
  }
`;
export const elasticSearchCreateDocOrTemp = /* GraphQL */ `
  query ElasticSearchCreateDocOrTemp(
    $id: Int
    $index: String
    $template: String
  ) {
    elasticSearchCreateDocOrTemp(id: $id, index: $index, template: $template)
  }
`;
export const elasticSearchDeleteDocOrTemp = /* GraphQL */ `
  query ElasticSearchDeleteDocOrTemp($id: Int, $index: String) {
    elasticSearchDeleteDocOrTemp(id: $id, index: $index)
  }
`;
export const elasticSearchFetchDoc = /* GraphQL */ `
  query ElasticSearchFetchDoc($doc_id: Int) {
    elasticSearchFetchDoc(doc_id: $doc_id)
  }
`;
export const elasticSearchFetchTemp = /* GraphQL */ `
  query ElasticSearchFetchTemp($template_id: Int) {
    elasticSearchFetchTemp(template_id: $template_id)
  }
`;
export const elasticSearchUpdateDocOrTemp = /* GraphQL */ `
  query ElasticSearchUpdateDocOrTemp(
    $id: Int
    $index: String
    $template: String
  ) {
    elasticSearchUpdateDocOrTemp(id: $id, index: $index, template: $template)
  }
`;
export const generatePdf = /* GraphQL */ `
  query GeneratePdf($docData: String!) {
    generatePdf(docData: $docData)
  }
`;
export const getAlgorithm = /* GraphQL */ `
  query GetAlgorithm($scopeType: Int) {
    getAlgorithm(scopeType: $scopeType)
  }
`;
export const getAssignDefaultTemplates = /* GraphQL */ `
  query GetAssignDefaultTemplates($user_id: Int) {
    getAssignDefaultTemplates(user_id: $user_id)
  }
`;
export const getAuthToken = /* GraphQL */ `
  query GetAuthToken($client_id: Int, $token: String) {
    getAuthToken(client_id: $client_id, token: $token)
  }
`;
export const getCategoriesWithLineitemsRedis = /* GraphQL */ `
  query GetCategoriesWithLineitemsRedis($id: Int) {
    getCategoriesWithLineitemsRedis(id: $id)
  }
`;
export const getCategoryByUserID = /* GraphQL */ `
  query GetCategoryByUserID($team_id: Int!, $user_id: Int!) {
    getCategoryByUserID(team_id: $team_id, user_id: $user_id) {
      category_id
      default_data
      name
      scope_type_id
      status
      team_id
      user_id
      __typename
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($client_id: Int) {
    getClient(client_id: $client_id) {
      account_balance
      admin_id
      client_id
      first_name
      last_name
      partner_id
      pd_team_edit
      team_id
      token
      transactional_account_balance
      user_pass
      user_role_id
      username
      __typename
    }
  }
`;
export const getClientByID = /* GraphQL */ `
  query GetClientByID($client_id: Int) {
    getClientByID(client_id: $client_id) {
      account_balance
      admin_id
      client_id
      first_name
      last_name
      partner_id
      pd_team_edit
      team_id
      token
      transactional_account_balance
      user_pass
      user_role_id
      username
      __typename
    }
  }
`;
export const getCompletedScopesByClientID = /* GraphQL */ `
  query GetCompletedScopesByClientID(
    $client_id: Int!
    $filter_ids: String
    $limit: Int
    $order_id: Int
    $order_ids: String
    $search: String
  ) {
    getCompletedScopesByClientID(
      client_id: $client_id
      filter_ids: $filter_ids
      limit: $limit
      order_id: $order_id
      order_ids: $order_ids
      search: $search
    )
  }
`;
export const getContactByClientID = /* GraphQL */ `
  query GetContactByClientID($client_id: Int!, $limit: Int, $type: String!) {
    getContactByClientID(client_id: $client_id, limit: $limit, type: $type)
  }
`;
export const getContactById = /* GraphQL */ `
  query GetContactById($contact_id: Int) {
    getContactById(contact_id: $contact_id)
  }
`;
export const getDefaultCategories = /* GraphQL */ `
  query GetDefaultCategories {
    getDefaultCategories {
      category_id
      default_data
      name
      scope_type_id
      status
      team_id
      user_id
      __typename
    }
  }
`;
export const getDefaultManufacturer = /* GraphQL */ `
  query GetDefaultManufacturer {
    getDefaultManufacturer {
      manufacturer_id
      name
      __typename
    }
  }
`;
export const getDefaultManufacturersRedis = /* GraphQL */ `
  query GetDefaultManufacturersRedis($id: Int) {
    getDefaultManufacturersRedis(id: $id)
  }
`;
export const getDefaultTemplates = /* GraphQL */ `
  query GetDefaultTemplates {
    getDefaultTemplates {
      data
      date_created
      date_updated
      description
      doc_type_id
      name
      shared
      team_id
      template_id
      update_user_id
      user_id
      __typename
    }
  }
`;
export const getDefaultTemplatesRedis = /* GraphQL */ `
  query GetDefaultTemplatesRedis($id: Int) {
    getDefaultTemplatesRedis(id: $id)
  }
`;
export const getDocCatagories = /* GraphQL */ `
  query GetDocCatagories($doc_id: Int!) {
    getDocCatagories(doc_id: $doc_id)
  }
`;
export const getDocId = /* GraphQL */ `
  query GetDocId($template_id: Int!) {
    getDocId(template_id: $template_id)
  }
`;
export const getDocLineItems = /* GraphQL */ `
  query GetDocLineItems($doc_id: Int!) {
    getDocLineItems(doc_id: $doc_id)
  }
`;
export const getDocOrderIds = /* GraphQL */ `
  query GetDocOrderIds($doc_id: Int) {
    getDocOrderIds(doc_id: $doc_id)
  }
`;
export const getDocStructure = /* GraphQL */ `
  query GetDocStructure($doc_id: Int!) {
    getDocStructure(doc_id: $doc_id)
  }
`;
export const getDocumentByID = /* GraphQL */ `
  query GetDocumentByID($doc_id: Int!) {
    getDocumentByID(doc_id: $doc_id) {
      claim_number
      contact_id
      date_created
      date_updated
      doc_id
      doc_type_id
      footer
      header
      invoice_label
      label
      location
      name
      notes
      options
      owner_contact_id
      pdf
      ref_id
      structure_count
      team_id
      template_id
      user_id
      __typename
    }
  }
`;
export const getDocumentByTeamID = /* GraphQL */ `
  query GetDocumentByTeamID(
    $created_date_end: String
    $created_date_start: String
    $doc_type: Int
    $limit: Int
    $name_location: String
    $offset: Int
    $ref_claim_num: String
    $team_id: Int
    $updated_date_end: String
    $updated_date_start: String
  ) {
    getDocumentByTeamID(
      created_date_end: $created_date_end
      created_date_start: $created_date_start
      doc_type: $doc_type
      limit: $limit
      name_location: $name_location
      offset: $offset
      ref_claim_num: $ref_claim_num
      team_id: $team_id
      updated_date_end: $updated_date_end
      updated_date_start: $updated_date_start
    )
  }
`;
export const getDocumentByUserID = /* GraphQL */ `
  query GetDocumentByUserID($team_id: Int!, $user_id: Int!) {
    getDocumentByUserID(team_id: $team_id, user_id: $user_id) {
      claim_number
      contact_id
      date_created
      date_updated
      doc_id
      doc_type_id
      footer
      header
      invoice_label
      label
      location
      name
      notes
      options
      owner_contact_id
      pdf
      ref_id
      structure_count
      team_id
      template_id
      user_id
      __typename
    }
  }
`;
export const getDocumentByUserIDExport = /* GraphQL */ `
  query GetDocumentByUserIDExport(
    $limit: Int!
    $team_id: Int!
    $user_id: Int!
  ) {
    getDocumentByUserIDExport(
      limit: $limit
      team_id: $team_id
      user_id: $user_id
    )
  }
`;
export const getDocumentByUserIDx = /* GraphQL */ `
  query GetDocumentByUserIDx(
    $created_date_end: String
    $created_date_start: String
    $doc_type: Int
    $limit: Int
    $name_location: String
    $offset: Int
    $ref_claim_num: String
    $team_id: Int
    $updated_date_end: String
    $updated_date_start: String
    $user_id: Int
  ) {
    getDocumentByUserIDx(
      created_date_end: $created_date_end
      created_date_start: $created_date_start
      doc_type: $doc_type
      limit: $limit
      name_location: $name_location
      offset: $offset
      ref_claim_num: $ref_claim_num
      team_id: $team_id
      updated_date_end: $updated_date_end
      updated_date_start: $updated_date_start
      user_id: $user_id
    )
  }
`;
export const getDocumentEstimateData = /* GraphQL */ `
  query GetDocumentEstimateData($doc_id: Int) {
    getDocumentEstimateData(doc_id: $doc_id)
  }
`;
export const getDocumentWorkOrderData = /* GraphQL */ `
  query GetDocumentWorkOrderData($doc_id: Int) {
    getDocumentWorkOrderData(doc_id: $doc_id)
  }
`;
export const getDocumentsListingElasticSearch = /* GraphQL */ `
  query GetDocumentsListingElasticSearch(
    $created_date_end: String
    $created_date_start: String
    $doc_type_id: Int
    $limit: Int
    $name_location: String
    $offset: Int
    $ref_claim_num: String
    $team_id: Int
    $updated_date_end: String
    $updated_date_start: String
    $user_id: Int
  ) {
    getDocumentsListingElasticSearch(
      created_date_end: $created_date_end
      created_date_start: $created_date_start
      doc_type_id: $doc_type_id
      limit: $limit
      name_location: $name_location
      offset: $offset
      ref_claim_num: $ref_claim_num
      team_id: $team_id
      updated_date_end: $updated_date_end
      updated_date_start: $updated_date_start
      user_id: $user_id
    )
  }
`;
export const getEsDownDocs = /* GraphQL */ `
  query GetEsDownDocs($team_id: Int) {
    getEsDownDocs(team_id: $team_id)
  }
`;
export const getEstimateTemplates = /* GraphQL */ `
  query GetEstimateTemplates($default_user_id: Int, $user_id: Int) {
    getEstimateTemplates(default_user_id: $default_user_id, user_id: $user_id)
  }
`;
export const getFiles = /* GraphQL */ `
  query GetFiles($customerIds: String!) {
    getFiles(customerIds: $customerIds)
  }
`;
export const getFinancingOptions = /* GraphQL */ `
  query GetFinancingOptions($doc_id: Int!) {
    getFinancingOptions(doc_id: $doc_id)
  }
`;
export const getFromRedis = /* GraphQL */ `
  query GetFromRedis($id: Int, $index: String) {
    getFromRedis(id: $id, index: $index)
  }
`;
export const getLineItemByID = /* GraphQL */ `
  query GetLineItemByID($line_item_id: Int!) {
    getLineItemByID(line_item_id: $line_item_id) {
      adjusted_quantity
      algorithm
      apply_op
      color
      convert_round
      convert_to
      cost
      description
      doc_category_id
      doc_id
      doc_line_item_id
      doc_structure_id
      doc_type_id
      is_default_by_user
      labor_cost
      line_item_code
      line_item_id
      manufacturer
      manufacturer_id
      material_cost
      name
      notes
      overhead
      product
      profit
      quantity
      ratio_from
      ratio_to
      remove_prices
      scope_type_id
      sku
      sort_order
      status
      sub_line_item
      team_id
      unit
      user_id
      waste
      __typename
    }
  }
`;
export const getLineItemByIDs = /* GraphQL */ `
  query GetLineItemByIDs($ids: String) {
    getLineItemByIDs(ids: $ids)
  }
`;
export const getLineItemByMan = /* GraphQL */ `
  query GetLineItemByMan(
    $default_user_id: Int
    $doc_type_id: Int
    $manufacturer_id: Int
    $team_id: Int
  ) {
    getLineItemByMan(
      default_user_id: $default_user_id
      doc_type_id: $doc_type_id
      manufacturer_id: $manufacturer_id
      team_id: $team_id
    ) {
      adjusted_quantity
      algorithm
      apply_op
      color
      convert_round
      convert_to
      cost
      description
      doc_category_id
      doc_id
      doc_line_item_id
      doc_structure_id
      doc_type_id
      is_default_by_user
      labor_cost
      line_item_code
      line_item_id
      manufacturer
      manufacturer_id
      material_cost
      name
      notes
      overhead
      product
      profit
      quantity
      ratio_from
      ratio_to
      remove_prices
      scope_type_id
      sku
      sort_order
      status
      sub_line_item
      team_id
      unit
      user_id
      waste
      __typename
    }
  }
`;
export const getManufacturerLineItemRedis = /* GraphQL */ `
  query GetManufacturerLineItemRedis($id: Int) {
    getManufacturerLineItemRedis(id: $id)
  }
`;
export const getMarkups = /* GraphQL */ `
  query GetMarkups($team_id: Int!, $user_id: Int!) {
    getMarkups(team_id: $team_id, user_id: $user_id)
  }
`;
export const getMxDocID = /* GraphQL */ `
  query GetMxDocID($id: Int) {
    getMxDocID(id: $id)
  }
`;
export const getOderScopeLoc = /* GraphQL */ `
  query GetOderScopeLoc($order_id: Int) {
    getOderScopeLoc(order_id: $order_id)
  }
`;
export const getProductAndManufacturerId = /* GraphQL */ `
  query GetProductAndManufacturerId($data: String) {
    getProductAndManufacturerId(data: $data)
  }
`;
export const getReportsRedis = /* GraphQL */ `
  query GetReportsRedis($id: Int) {
    getReportsRedis(id: $id)
  }
`;
export const getSearchedContactsByClientID = /* GraphQL */ `
  query GetSearchedContactsByClientID(
    $client_id: Int!
    $limit: Int!
    $search: String!
    $selected_contact: Int
    $type: String!
  ) {
    getSearchedContactsByClientID(
      client_id: $client_id
      limit: $limit
      search: $search
      selected_contact: $selected_contact
      type: $type
    )
  }
`;
export const getTemplate = /* GraphQL */ `
  query GetTemplate($template_id: Int!) {
    getTemplate(template_id: $template_id) {
      data
      date_created
      date_updated
      description
      doc_type_id
      name
      shared
      team_id
      template_id
      update_user_id
      user_id
      __typename
    }
  }
`;
export const getTemplateDocument = /* GraphQL */ `
  query GetTemplateDocument($template_id: Int!) {
    getTemplateDocument(template_id: $template_id) {
      claim_number
      contact_id
      date_created
      date_updated
      doc_id
      doc_type_id
      footer
      header
      invoice_label
      label
      location
      name
      notes
      options
      owner_contact_id
      pdf
      ref_id
      structure_count
      team_id
      template_id
      user_id
      __typename
    }
  }
`;
export const getTemplatesByClientID = /* GraphQL */ `
  query GetTemplatesByClientID($doc_type_id: Int!, $user_id: ID!) {
    getTemplatesByClientID(doc_type_id: $doc_type_id, user_id: $user_id) {
      data
      date_created
      date_updated
      description
      doc_type_id
      name
      shared
      team_id
      template_id
      update_user_id
      user_id
      __typename
    }
  }
`;
export const getTemplatesByClientIDx = /* GraphQL */ `
  query GetTemplatesByClientIDx(
    $doc_type_id: Int
    $limit: Int
    $offset: Int
    $user_id: Int
  ) {
    getTemplatesByClientIDx(
      doc_type_id: $doc_type_id
      limit: $limit
      offset: $offset
      user_id: $user_id
    )
  }
`;
export const getTemplatesByTeamID = /* GraphQL */ `
  query GetTemplatesByTeamID(
    $doc_type_id: Int
    $limit: Int
    $offset: Int
    $search: String
    $team_id: Int
  ) {
    getTemplatesByTeamID(
      doc_type_id: $doc_type_id
      limit: $limit
      offset: $offset
      search: $search
      team_id: $team_id
    )
  }
`;
export const getTemplatesByTeamIDElasticsearch = /* GraphQL */ `
  query GetTemplatesByTeamIDElasticsearch(
    $doc_type_id: Int
    $limit: Int
    $offset: Int
    $search: String
    $team_id: Int
  ) {
    getTemplatesByTeamIDElasticsearch(
      doc_type_id: $doc_type_id
      limit: $limit
      offset: $offset
      search: $search
      team_id: $team_id
    )
  }
`;
export const getToken = /* GraphQL */ `
  query GetToken($accessPass: String!, $userName: String!) {
    getToken(accessPass: $accessPass, userName: $userName)
  }
`;
export const getUnits = /* GraphQL */ `
  query GetUnits {
    getUnits {
      label
      name
      round
      unit_id
      __typename
    }
  }
`;
export const getUserByToken = /* GraphQL */ `
  query GetUserByToken($token: String) {
    getUserByToken(token: $token) {
      account_balance
      admin_id
      client_id
      first_name
      last_name
      partner_id
      pd_team_edit
      team_id
      token
      transactional_account_balance
      user_pass
      user_role_id
      username
      __typename
    }
  }
`;
export const getUserDataForProxyLogin = /* GraphQL */ `
  query GetUserDataForProxyLogin($email: String, $id: Int) {
    getUserDataForProxyLogin(email: $email, id: $id)
  }
`;
export const getUsersManufacturers = /* GraphQL */ `
  query GetUsersManufacturers($team_id: Int) {
    getUsersManufacturers(team_id: $team_id)
  }
`;
export const getWOTemplates = /* GraphQL */ `
  query GetWOTemplates($default_user_id: Int, $user_id: Int) {
    getWOTemplates(default_user_id: $default_user_id, user_id: $user_id)
  }
`;
export const removeCategory = /* GraphQL */ `
  query RemoveCategory($category_id: ID!) {
    removeCategory(category_id: $category_id)
  }
`;
export const removeContact = /* GraphQL */ `
  query RemoveContact($contact_id: ID!) {
    removeContact(contact_id: $contact_id)
  }
`;
export const removeDocOrderId = /* GraphQL */ `
  query RemoveDocOrderId($doc_id: Int, $order_id: Int) {
    removeDocOrderId(doc_id: $doc_id, order_id: $order_id)
  }
`;
export const removeTemplate = /* GraphQL */ `
  query RemoveTemplate($template_id: ID!) {
    removeTemplate(template_id: $template_id)
  }
`;
export const removeUsersManufacturers = /* GraphQL */ `
  query RemoveUsersManufacturers($manufacturer_id: Int) {
    removeUsersManufacturers(manufacturer_id: $manufacturer_id)
  }
`;
export const saveCategory = /* GraphQL */ `
  query SaveCategory(
    $default_data: String!
    $name: String!
    $scope_type_id: Int!
    $status: Int!
    $team_id: Int!
    $user_id: Int!
  ) {
    saveCategory(
      default_data: $default_data
      name: $name
      scope_type_id: $scope_type_id
      status: $status
      team_id: $team_id
      user_id: $user_id
    )
  }
`;
export const saveDocMarkup = /* GraphQL */ `
  query SaveDocMarkup(
    $doc_id: Int!
    $for: String
    $markup_id: Int!
    $name: String
    $percent: Float
    $sort_order: Int
    $type: String!
    $value: Float
  ) {
    saveDocMarkup(
      doc_id: $doc_id
      for: $for
      markup_id: $markup_id
      name: $name
      percent: $percent
      sort_order: $sort_order
      type: $type
      value: $value
    )
  }
`;
export const saveDocOrder = /* GraphQL */ `
  query SaveDocOrder(
    $address: String!
    $doc_id: Int!
    $name: String!
    $order_id: Int!
    $scope_type_id: Int!
  ) {
    saveDocOrder(
      address: $address
      doc_id: $doc_id
      name: $name
      order_id: $order_id
      scope_type_id: $scope_type_id
    )
  }
`;
export const saveEstimateDoc = /* GraphQL */ `
  query SaveEstimateDoc($data: String!, $doc_id: Int!) {
    saveEstimateDoc(data: $data, doc_id: $doc_id)
  }
`;
export const saveFinancingOptions = /* GraphQL */ `
  query SaveFinancingOptions($options: String!) {
    saveFinancingOptions(options: $options)
  }
`;
export const saveLineItmAsDefault = /* GraphQL */ `
  query SaveLineItmAsDefault(
    $adjusted_quantity: Float
    $algorithm: String
    $apply_op: Int
    $client_id: Int!
    $color: String
    $convert_round: Boolean
    $convert_to: String
    $cost: Float
    $description: String
    $doc_line_item_id: Int
    $doc_type_id: Int
    $force_update: Boolean
    $labor_cost: Float
    $line_item_code: String
    $line_item_id: Int
    $manufacturer: String
    $manufacturer_id: Int
    $material_cost: Float
    $name: String!
    $notes: String
    $overhead: Float
    $product: String
    $profit: Float
    $quantity: Float
    $ratio_from: Float
    $ratio_to: Float
    $scope_type_id: Int
    $sku: String
    $status: String
    $sub_line_item: String
    $team_id: Int!
    $unit: String
    $waste: Float
  ) {
    saveLineItmAsDefault(
      adjusted_quantity: $adjusted_quantity
      algorithm: $algorithm
      apply_op: $apply_op
      client_id: $client_id
      color: $color
      convert_round: $convert_round
      convert_to: $convert_to
      cost: $cost
      description: $description
      doc_line_item_id: $doc_line_item_id
      doc_type_id: $doc_type_id
      force_update: $force_update
      labor_cost: $labor_cost
      line_item_code: $line_item_code
      line_item_id: $line_item_id
      manufacturer: $manufacturer
      manufacturer_id: $manufacturer_id
      material_cost: $material_cost
      name: $name
      notes: $notes
      overhead: $overhead
      product: $product
      profit: $profit
      quantity: $quantity
      ratio_from: $ratio_from
      ratio_to: $ratio_to
      scope_type_id: $scope_type_id
      sku: $sku
      status: $status
      sub_line_item: $sub_line_item
      team_id: $team_id
      unit: $unit
      waste: $waste
    )
  }
`;
export const saveTemplate = /* GraphQL */ `
  query SaveTemplate(
    $data: String!
    $doc_type_id: Int!
    $name: String!
    $team_id: Int!
    $update_user_id: Int!
    $user_id: Int!
  ) {
    saveTemplate(
      data: $data
      doc_type_id: $doc_type_id
      name: $name
      team_id: $team_id
      update_user_id: $update_user_id
      user_id: $user_id
    )
  }
`;
export const saveTemplateAs = /* GraphQL */ `
  query SaveTemplateAs(
    $data: String!
    $description: String
    $doc_type_id: Int!
    $name: String!
    $team_id: Int!
    $template_id: Int
    $update_user_id: Int!
    $user_id: Int!
  ) {
    saveTemplateAs(
      data: $data
      description: $description
      doc_type_id: $doc_type_id
      name: $name
      team_id: $team_id
      template_id: $template_id
      update_user_id: $update_user_id
      user_id: $user_id
    )
  }
`;
export const saveTemplateJson = /* GraphQL */ `
  query SaveTemplateJson(
    $data: String!
    $date_updated: String
    $es_down_flag: String
    $template_id: Int!
    $update_user_id: Int
  ) {
    saveTemplateJson(
      data: $data
      date_updated: $date_updated
      es_down_flag: $es_down_flag
      template_id: $template_id
      update_user_id: $update_user_id
    )
  }
`;
export const saveUsersManufacturers = /* GraphQL */ `
  query SaveUsersManufacturers($name: String, $team_id: Int, $user_id: Int) {
    saveUsersManufacturers(name: $name, team_id: $team_id, user_id: $user_id)
  }
`;
export const saveWorkOrderDoc = /* GraphQL */ `
  query SaveWorkOrderDoc($data: String!, $doc_id: Int!) {
    saveWorkOrderDoc(data: $data, doc_id: $doc_id)
  }
`;
export const searchAllLineItems = /* GraphQL */ `
  query SearchAllLineItems(
    $client_id: Int
    $doc_type_id: Int
    $man_ids: String
    $search: String
    $team_id: Int
  ) {
    searchAllLineItems(
      client_id: $client_id
      doc_type_id: $doc_type_id
      man_ids: $man_ids
      search: $search
      team_id: $team_id
    )
  }
`;
export const sendEmail = /* GraphQL */ `
  query SendEmail(
    $client_email: String!
    $client_id: Int!
    $email_msg: String!
    $subject: String!
    $to_email: String!
    $url_download: String!
  ) {
    sendEmail(
      client_email: $client_email
      client_id: $client_id
      email_msg: $email_msg
      subject: $subject
      to_email: $to_email
      url_download: $url_download
    )
  }
`;
export const setEsDownFlagDoc = /* GraphQL */ `
  query SetEsDownFlagDoc($doc_id: Int!, $flag: String!) {
    setEsDownFlagDoc(doc_id: $doc_id, flag: $flag)
  }
`;
export const setEsDownFlagTemp = /* GraphQL */ `
  query SetEsDownFlagTemp($flag: String!, $template_id: Int!) {
    setEsDownFlagTemp(flag: $flag, template_id: $template_id)
  }
`;
export const shareTemplate = /* GraphQL */ `
  query ShareTemplate($shared: Int!, $template_id: ID!) {
    shareTemplate(shared: $shared, template_id: $template_id)
  }
`;
export const shareTemplateElasticsearch = /* GraphQL */ `
  query ShareTemplateElasticsearch($shared: Int!, $template_id: ID!) {
    shareTemplateElasticsearch(shared: $shared, template_id: $template_id)
  }
`;
export const updateClientBranding = /* GraphQL */ `
  query UpdateClientBranding($client_id: Int!, $color: String, $logo: String) {
    updateClientBranding(client_id: $client_id, color: $color, logo: $logo)
  }
`;
export const updateClientEstContact = /* GraphQL */ `
  query UpdateClientEstContact(
    $client_id: Int!
    $prodocs_estimate_contact_email: String
    $prodocs_estimate_contact_first_name: String
    $prodocs_estimate_contact_last_name: String
    $prodocs_estimate_contact_phone: String
  ) {
    updateClientEstContact(
      client_id: $client_id
      prodocs_estimate_contact_email: $prodocs_estimate_contact_email
      prodocs_estimate_contact_first_name: $prodocs_estimate_contact_first_name
      prodocs_estimate_contact_last_name: $prodocs_estimate_contact_last_name
      prodocs_estimate_contact_phone: $prodocs_estimate_contact_phone
    )
  }
`;
export const updateClientWrkContact = /* GraphQL */ `
  query UpdateClientWrkContact(
    $client_id: Int!
    $prodocs_work_order_contact_email: String
    $prodocs_work_order_contact_first_name: String
    $prodocs_work_order_contact_last_name: String
    $prodocs_work_order_contact_phone: String
  ) {
    updateClientWrkContact(
      client_id: $client_id
      prodocs_work_order_contact_email: $prodocs_work_order_contact_email
      prodocs_work_order_contact_first_name: $prodocs_work_order_contact_first_name
      prodocs_work_order_contact_last_name: $prodocs_work_order_contact_last_name
      prodocs_work_order_contact_phone: $prodocs_work_order_contact_phone
    )
  }
`;
export const updateContactAddress = /* GraphQL */ `
  query UpdateContactAddress(
    $address: String
    $address_2: String
    $address_id: Int
    $city: String
    $company: String
    $country_id: Int
    $name: String
    $postcode: String
    $zone_id: Int
  ) {
    updateContactAddress(
      address: $address
      address_2: $address_2
      address_id: $address_id
      city: $city
      company: $company
      country_id: $country_id
      name: $name
      postcode: $postcode
      zone_id: $zone_id
    )
  }
`;
export const updateContactInfo = /* GraphQL */ `
  query UpdateContactInfo(
    $contact_id: Int
    $email: String
    $first_name: String
    $last_name: String
    $phone: String
  ) {
    updateContactInfo(
      contact_id: $contact_id
      email: $email
      first_name: $first_name
      last_name: $last_name
      phone: $phone
    )
  }
`;
export const updateContactInfox = /* GraphQL */ `
  query UpdateContactInfox(
    $company: String
    $contact_id: Int
    $email: String
    $first_name: String
    $last_name: String
    $phone: String
  ) {
    updateContactInfox(
      company: $company
      contact_id: $contact_id
      email: $email
      first_name: $first_name
      last_name: $last_name
      phone: $phone
    )
  }
`;
export const updateDocCatSortOrder = /* GraphQL */ `
  query UpdateDocCatSortOrder($doc_category_id: Int, $sort_order: Int) {
    updateDocCatSortOrder(
      doc_category_id: $doc_category_id
      sort_order: $sort_order
    )
  }
`;
export const updateDocCategoryName = /* GraphQL */ `
  query UpdateDocCategoryName($doc_category_id: Int, $name: String) {
    updateDocCategoryName(doc_category_id: $doc_category_id, name: $name)
  }
`;
export const updateDocContactId = /* GraphQL */ `
  query UpdateDocContactId($contact_id: Int, $doc_id: Int) {
    updateDocContactId(contact_id: $contact_id, doc_id: $doc_id)
  }
`;
export const updateDocFooter = /* GraphQL */ `
  query UpdateDocFooter($doc_id: Int, $footer: String) {
    updateDocFooter(doc_id: $doc_id, footer: $footer)
  }
`;
export const updateDocLineItemSortOrder = /* GraphQL */ `
  query UpdateDocLineItemSortOrder($doc_line_item_id: Int, $sort_order: Int) {
    updateDocLineItemSortOrder(
      doc_line_item_id: $doc_line_item_id
      sort_order: $sort_order
    )
  }
`;
export const updateDocModDate = /* GraphQL */ `
  query UpdateDocModDate(
    $date_updated: String
    $doc_id: Int
    $es_down_flag: String
    $pdf: String
    $updated_by: Int
  ) {
    updateDocModDate(
      date_updated: $date_updated
      doc_id: $doc_id
      es_down_flag: $es_down_flag
      pdf: $pdf
      updated_by: $updated_by
    )
  }
`;
export const updateDocNotes = /* GraphQL */ `
  query UpdateDocNotes($doc_id: Int, $notes: String) {
    updateDocNotes(doc_id: $doc_id, notes: $notes)
  }
`;
export const updateDocOptions = /* GraphQL */ `
  query UpdateDocOptions($doc_id: Int, $options: String) {
    updateDocOptions(doc_id: $doc_id, options: $options)
  }
`;
export const updateDocStructName = /* GraphQL */ `
  query UpdateDocStructName($doc_structure_id: Int, $name: String) {
    updateDocStructName(doc_structure_id: $doc_structure_id, name: $name)
  }
`;
export const updateDocStructureSortOrder = /* GraphQL */ `
  query UpdateDocStructureSortOrder($doc_structure_id: Int, $sort_order: Int) {
    updateDocStructureSortOrder(
      doc_structure_id: $doc_structure_id
      sort_order: $sort_order
    )
  }
`;
export const updateDocumentLabel = /* GraphQL */ `
  query UpdateDocumentLabel($doc_id: Int, $label: String) {
    updateDocumentLabel(doc_id: $doc_id, label: $label)
  }
`;
export const updateDocumentOptions = /* GraphQL */ `
  query UpdateDocumentOptions($doc_id: Int, $options: String) {
    updateDocumentOptions(doc_id: $doc_id, options: $options)
  }
`;
export const updateFinancingOptions = /* GraphQL */ `
  query UpdateFinancingOptions(
    $doc_id: Int!
    $financing_option: String
    $is_default: String
    $user_id: Int!
  ) {
    updateFinancingOptions(
      doc_id: $doc_id
      financing_option: $financing_option
      is_default: $is_default
      user_id: $user_id
    )
  }
`;
export const updateInvoiceLabel = /* GraphQL */ `
  query UpdateInvoiceLabel($doc_id: Int, $label: String) {
    updateInvoiceLabel(doc_id: $doc_id, label: $label)
  }
`;
export const updateLineItem = /* GraphQL */ `
  query UpdateLineItem(
    $adjusted_quantity: Float
    $apply_op: Int
    $cost: Float
    $doc_line_item_id: Int
    $labor_cost: Float
    $material_cost: Float
    $name: String
    $overhead: Float
    $profit: Float
    $quantity: Float
    $remove_prices: Int
    $unit: String
    $waste: Float
  ) {
    updateLineItem(
      adjusted_quantity: $adjusted_quantity
      apply_op: $apply_op
      cost: $cost
      doc_line_item_id: $doc_line_item_id
      labor_cost: $labor_cost
      material_cost: $material_cost
      name: $name
      overhead: $overhead
      profit: $profit
      quantity: $quantity
      remove_prices: $remove_prices
      unit: $unit
      waste: $waste
    )
  }
`;
export const updateLineItemWorkOrder = /* GraphQL */ `
  query UpdateLineItemWorkOrder(
    $adjusted_quantity: Float
    $algorithm: String
    $apply_op: Int
    $color: String
    $convert_round: Int
    $convert_to: String
    $cost: Float
    $doc_line_item_id: Int
    $manufacturer: String
    $name: String
    $quantity: Float
    $ratio_from: Float
    $ratio_to: Float
    $remove_prices: Int
    $unit: String
    $waste: Float
  ) {
    updateLineItemWorkOrder(
      adjusted_quantity: $adjusted_quantity
      algorithm: $algorithm
      apply_op: $apply_op
      color: $color
      convert_round: $convert_round
      convert_to: $convert_to
      cost: $cost
      doc_line_item_id: $doc_line_item_id
      manufacturer: $manufacturer
      name: $name
      quantity: $quantity
      ratio_from: $ratio_from
      ratio_to: $ratio_to
      remove_prices: $remove_prices
      unit: $unit
      waste: $waste
    )
  }
`;
export const updateMarkup = /* GraphQL */ `
  query UpdateMarkup(
    $doc_markup_id: Int
    $for: String
    $percent: Float
    $type: String
    $value: Float
  ) {
    updateMarkup(
      doc_markup_id: $doc_markup_id
      for: $for
      percent: $percent
      type: $type
      value: $value
    )
  }
`;
export const updateProjectInfo = /* GraphQL */ `
  query UpdateProjectInfo(
    $claim_number: String
    $doc_id: Int
    $location: String
    $name: String
    $ref_id: String
    $structure_count: Int
  ) {
    updateProjectInfo(
      claim_number: $claim_number
      doc_id: $doc_id
      location: $location
      name: $name
      ref_id: $ref_id
      structure_count: $structure_count
    )
  }
`;
export const updateProjectOwner = /* GraphQL */ `
  query UpdateProjectOwner($doc_id: Int, $owner_contact_id: Int) {
    updateProjectOwner(doc_id: $doc_id, owner_contact_id: $owner_contact_id)
  }
`;
export const updateQuickGenerate = /* GraphQL */ `
  query UpdateQuickGenerate($client_id: Int!, $one_click: Int) {
    updateQuickGenerate(client_id: $client_id, one_click: $one_click)
  }
`;
export const updateStructureCount = /* GraphQL */ `
  query UpdateStructureCount($doc_id: Int, $structure_count: Int) {
    updateStructureCount(doc_id: $doc_id, structure_count: $structure_count)
  }
`;
export const updateTempDescription = /* GraphQL */ `
  query UpdateTempDescription($description: String, $template_id: Int) {
    updateTempDescription(description: $description, template_id: $template_id)
  }
`;
export const updateTempEditRestriction = /* GraphQL */ `
  query UpdateTempEditRestriction($client_id: Int!, $pd_team_edit: Int) {
    updateTempEditRestriction(
      client_id: $client_id
      pd_team_edit: $pd_team_edit
    )
  }
`;
export const updateTemplateDescription = /* GraphQL */ `
  query UpdateTemplateDescription($description: String, $template_id: Int) {
    updateTemplateDescription(
      description: $description
      template_id: $template_id
    )
  }
`;
export const uploadFile = /* GraphQL */ `
  query UploadFile($customerId: Int!, $fileList: String!, $folderId: Int!) {
    uploadFile(
      customerId: $customerId
      fileList: $fileList
      folderId: $folderId
    )
  }
`;
export const uploadTemplate = /* GraphQL */ `
  query UploadTemplate(
    $data: String!
    $date: String
    $email: String!
    $team_id: Int!
    $user_id: Int!
  ) {
    uploadTemplate(
      data: $data
      date: $date
      email: $email
      team_id: $team_id
      user_id: $user_id
    )
  }
`;
